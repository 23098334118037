export const HELPER_URL = "http://127.0.0.1:5001";
export const OA_URL = "http://oa_api.xindacai.com";
// export const OA_URL = "http://127.0.0.1:5000";

export const NAVS = [
  {
    category: "福建税务",
    navs: [{ title: "申报清册", link: "/fj_tax/sbqc" }],
  },
  {
    category: "福建工商", // Market Regulation
    navs: [
      { title: "名称核准", link: "/fj_mr/mchz" },
      { title: "开业登记", link: "/fj_mr/kydj" },
    ],
  },
  {
    category: "其他工具",
    navs: [{ title: "图形识别", link: "/tools/ocr" }],
  },
  {
    category: "系统设置",
    navs: [{ title: "配置项", link: "/system/config" }],
  },
];

export const SBQC_EXPIRATION = 5; // 申报清册与截至日期的安全间隔天数
