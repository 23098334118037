import axios from "axios";
// import { createStore } from "vuex";

import { OA_URL } from "@/config.js";
import { getIndexByKeyValue } from "@/utils/common.js";

export default class BaseStore {
  constructor(model, namespaced = true) {
    this.base_url = `${OA_URL}/${model}`;
    this.namespaced = namespaced;
  }

  state() {
    return {
      pagination: { items: [], page_count: 0, total: 0 },
      all: [],
      params: { size: 50, page: 1, filter: {}, keywords: {} },
      batch: [], // 要批量修改的
    };
  }

  getters() {
    return {};
  }

  actions() {
    const base_url = this.base_url;
    return {
      async get(ctx, uid) {
        return await axios.get(`${base_url}/${uid}`);
      },
      async create(ctx, data) {
        return await axios.post(base_url, data);
      },
      async update({ dispatch }, { uid, patch, paginate }) {
        const res = await axios.put(`${base_url}/${uid}`, patch);
        if (paginate) {
          dispatch("pagination");
        }
        return res;
      },
      async batch_update(ctx, data) {
        return await axios.put(`${base_url}/batch/update`, data);
      },
      async delete(ctx, uid) {
        return await axios.delete(`${base_url}/${uid}`);
      },
      async all({ commit }, params = null) {
        const url = new URL(`${base_url}/all`);
        if (params) {
          url.search = new URLSearchParams(params);
        }
        const res = await axios.get(url.toString());
        commit("setAll", res.data);
        return res;
      },
      async search(ctx, { field, keyword }) {
        return await axios.get(
          `${base_url}/search?field=${field}&keyword=${keyword}`
        );
      },
      async pagination({ state, commit }, data = null) {
        const params = data != null ? data : state.params;
        const res = await axios.post(`${base_url}/pagination`, params);
        commit("setPagination", res.data);
      },
    };
  }

  mutations() {
    return {
      setAll(state, data) {
        state.all = data;
      },
      setPagination(state, data) {
        state.pagination = data;
      },
      updateParams(state, params) {
        state.params = params;
      },
      updateState(state, { uid, patch }) {
        let index = getIndexByKeyValue(state.pagination.items, "uid", uid);
        state.pagination.items.splice(index, 1, patch);
        index = getIndexByKeyValue(state.all, "uid", uid);
        state.all.splice(index, 1, patch);
      },
      deleteItem(state, uid) {
        let index = getIndexByKeyValue(state.pagination.items, "uid", uid);
        if (index >= 0) {
          state.pagination.items.splice(index, 1);
          state.pagination.total -= 1;
        }
        index = getIndexByKeyValue(state.all, "uid", uid);
        state.all.splice(index, 1);
      },
      setBatch(state, array) {
        state.batch = array;
      },
      clearBatch(state) {
        state.patch = [];
      },
    };
  }

  // toStore() {
  //   return createStore({
  //     namespaced: this.namespaced,
  //     state: this.state(),
  //     getters: this.getters(),
  //     actions: this.actions(),
  //     mutations: this.mutations(),
  //   });
  // }
  toStore() {
    return {
      namespaced: this.namespaced,
      state: this.state(),
      getters: this.getters(),
      actions: this.actions(),
      mutations: this.mutations(),
    };
  }
}
