import { defineComponent, h } from "vue";
import Modal from "@/components/Modal";

export default defineComponent({
  name: "Loading",
  components: { Modal },
  setup() {
    return () =>
      h(Modal, null, {
        default: () =>
          h("svg", {
            class: "animate-bounce h-6 w-6 rounded-full bg-blue-600",
            viewBox: "0 0 1024 1024",
          }),
      });
  },
});
