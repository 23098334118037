import { defineComponent, h, ref, computed, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import { ElNotification } from "element-plus";
import SideBar from "./components/SideBar";
import NavPanel from "./components/NavPanel";
import Loading from "./components/Loading";
import QrList from "./components/QrList.js";
import View from "./views/View.vue";
import { div } from "./utils/tag.js";
import { HELPER_URL } from "@/config.js";

export default defineComponent({
  name: "App",
  components: { SideBar, NavPanel, RouterView, Loading, View, QrList },
  props: {
    appStyle: {
      type: String,
      default: "flex flex-row h-screen w-screen overflow-hidden",
    },
  },
  setup(props) {
    const store = useStore();
    const showNavPanelRef = ref(true);
    const toggleNavPanel = () =>
      (showNavPanelRef.value = !showNavPanelRef.value);
    const showLoading = computed(() => store.state.loading);
    const qrList = computed(() => store.state.helper.qrList);
    const sysCfg = computed(() => store.state.systemConfig);

    const navs = computed(() => {
      if (sysCfg.value && sysCfg.value.navs) {
        return sysCfg.value.navs.value;
      }
      return null;
    });

    const title = computed(() => {
      if (sysCfg.value && sysCfg.value.base) {
        return sysCfg.value.base.value.title;
      }
      return null;
    });

    onMounted(async () => {
      store.dispatch("getSystemConfig").then((res) => {
        const payload = {
          pkg: "app.config",
          func: "update_config",
          kwargs: {
            config_list: res.data,
          },
        };
        store.dispatch("helper/run", payload);
      });
    });

    // 定时从后端的队列获取数据，并做出相应的操作
    setInterval(async () => {
      const api = axios.create({
        baseURL: HELPER_URL,
      });
      const res = await api.get("/queue");

      if (!res.data) return;
      res.data.map((i) => {
        if (i.type === "store") {
          console.log(i);
          store[i.content.do](i.content["type"], i.content["payload"]);
        } else if (i.type === "message") {
          ElNotification[i.content.type]({
            message: i.content.message,
            duration: 0,
          });
        }
      });
    }, 5000);

    return () =>
      div({ class: props.appStyle }, [
        h(SideBar, { onToggleNavPanel: toggleNavPanel }),
        showNavPanelRef.value &&
          navs.value &&
          title.value &&
          h(NavPanel, {
            title: title.value,
            navs: navs.value,
          }),
        h(View),
        showLoading.value && h(Loading),
        qrList.value.length > 0 && h(QrList, { qrList: qrList.value }),
      ]);
  },
});
