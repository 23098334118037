import { defineComponent } from "vue";
import { div } from "@/utils/tag";

export default defineComponent({
  name: "Modal",
  props: {
    modalStyle: {
      type: String,
      default:
        "absolute inset-0 h-screen w-screen bg-white opacity-95 flex justify-center items-center",
    },
  },
  setup(props, { slots }) {
    return () =>
      div({ class: props.modalStyle }, slots.default && slots.default());
  },
});
