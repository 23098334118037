/* 显示后端发送古来的微信二维码  */
import { defineComponent, h } from "vue";

export default defineComponent({
  name: "QrList",
  props: {
    qrList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    return () =>
      h("div", { class: "absolute bottom-0 w-full p-2 bg-white opacity-50" }, [
        h(
          "div",
          { class: "w-100 text-lg text-gray-700 text-center pt-2 pb-4" },
          "请微信扫码登录"
        ),
        h(
          "div",
          { class: "flex flex-row justify-center" },
          props.qrList.map((qr) => {
            return h(
              "div",
              { class: "flex flex-col space-x-4 justify-center items-center" },
              [
                h("img", {
                  class: "w-24 h-24 rounded-lg",
                  src: `data:image/png;base64, ${qr.img}`,
                }),
                h(
                  "span",
                  { class: "w-24 truncate text-gray-600 text-sm py-1" },
                  qr.title
                ),
              ]
            );
          })
        ),
      ]);
  },
});
