<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    :total="total"
    layout="total, sizes, prev, pager, next, jumper"
    small
    class="flex-row items-center"
  />
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Pagination",
  setup() {
    const route = useRoute();
    const model = route.meta.model;

    const pageSizes = [10, 20, 50, 100, 500];
    const store = useStore();
    const params = computed(() => store.state[model].params);
    const currentPage = computed(() => params.value.page);
    const pageSize = computed(() => params.value.size);
    const total = computed(() => store.state[model].pagination.total);
    const paginate = () => store.dispatch(`${model}/pagination`);

    const handleSizeChange = (size) => {
      store.commit(`${model}/updateParams`, {
        ...params.value,
        size,
        page: 1,
      });
      paginate();
    };

    const handleCurrentChange = (page) => {
      store.commit(`${model}/updateParams`, {
        ...params.value,
        page: page,
      });
      paginate();
    };
    return {
      pageSizes,
      currentPage,
      pageSize,
      total,
      handleSizeChange,
      handleCurrentChange,
    };
  },
});
</script>
