import { defineComponent, h, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MenuIcon from "./icon/menu.vue";
import { div } from "@/utils/tag";

export default defineComponent({
  name: "SideBar",
  components: { MenuIcon },
  props: {
    sideBarStyle: {
      type: String,
      default: "flex flex-col flex-shrink-0 justify-between w-12 bg-white",
    },
    toggleButtonStyle: {
      type: String,
      default: "w-full flex justify-center items-center pb-4 cursor-pointer",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const user_name = computed(() => store.state.user.me.name);

    const logout = () => {
      store.commit("user/clearMe");
      router.push("/user/login");
    };

    const togglePanelButton = div(
      {
        onClick: () => emit("toggle-nav-panel"),
        class: props.toggleButtonStyle,
      },
      h(MenuIcon)
    );

    return () =>
      div({ class: props.sideBarStyle }, [
        div(
          { class: "flex justify-center pt-4" },
          div(
            {
              onClick: logout,
              class:
                "h-10 w-10 rounded-full bg-gray-400 flex justify-center items-center shadow-lg text-gray-200 cursor-pointer",
            },
            user_name.value && user_name.value.slice(1)
          )
        ),
        togglePanelButton,
      ]);
  },
});
