import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { div, span } from "@/utils/tag";
import { toggleElmentById } from "@/utils/common";

export default defineComponent({
  name: "NavCollapse",
  props: {
    navCollaspeStyle: {
      type: String,
      default: "block w-full divide-y divide-gray-50",
    },
    navGroupHeaderStyle: {
      type: String,
      default:
        "w-full py-2 px-6 flex justify-between items-center bg-gray-200 text-gray-700 cursor-pointer hover:bg-white",
    },
    navGroupHeaderTagStyle: {
      type: String,
      default:
        "bg-gray-500 rounded-full flex w-4 h-4  text-gray-50 shadow-inner items-center justify-center",
    },
    navStyle: {
      type: String,
      default:
        "block px-6 py-2 cursor-pointer bg-white hover:bg-gray-50 hover:text-gray-600",
    },
    navs: { type: Array, required: true },
  },
  setup(props) {
    const router = useRouter();

    const PanelHeader = (group, gIdx) =>
      div(
        {
          class: props.navGroupHeaderStyle,
          onClick: () => toggleElmentById(`nav_group_${gIdx}`),
        },
        [
          span(group.category),
          span({ class: props.navGroupHeaderTagStyle }, group.navs.length),
        ]
      );

    const navs = (items) =>
      items.map((i) =>
        div(
          { class: props.navStyle, onClick: () => router.push(i.link) },
          i.title
        )
      );

    const PanelBody = (group, gIdx) =>
      div(
        {
          class: "divide-y divide-gray-100",
          style: { display: "none" },
          id: `nav_group_${gIdx}`,
        },
        navs(group.navs)
      );

    const Panel = (group, gIdx) =>
      div({ class: "text-sm text-gray-500" }, [
        PanelHeader(group, gIdx),
        PanelBody(group, gIdx),
      ]);

    const NavPanels = props.navs.map((group, index) => Panel(group, index));

    return () => div({ class: props.navCollaspeStyle }, NavPanels);
  },
});
