import axios from "axios";
import { createApp } from "vue";
import App from "./App.js";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import locale from "@/utils/element_locale.js";
import "element-plus/lib/theme-chalk/index.css";
import "./assets/style.css";
import "./assets/tailwind.css";

// global config
axios.defaults.headers.common["Token"] = localStorage.getItem("token");
axios.interceptors.request.use((config) => {
  store.commit("setLoading", true);
  return config;
});

axios.interceptors.response.use(
  (response) => {
    store.commit("setLoading", false);
    return response;
  },
  (err) => {
    store.commit("setLoading", false);
    throw err;
  }
);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.config.devtools = true;
app.mount("#app");
