<template>
  <div class="w-full flex flex-col bg-white overflow-hidden">
    <div class="flex w-full justify-between items-center px-4 h-16 bg-white">
      <div
        class="text-gray-500 text-xl tracking-widest font-serif font-extrabold"
      >
        <i class="el-icon-back cursor-pointer" @click="goBack" />
        {{ title }}
      </div>
      <el-input
        v-if="showSearch"
        class="w-64"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        @keyup.enter="search"
        @clear="search"
        clearable
        v-model="keyword"
      />
    </div>

    <div class="w-full h-full bg-gray-50 overflow-auto">
      <router-view />
    </div>

    <div
      v-if="showFooter"
      class="w-full h-8  flex flex-row text-gray-500 justify-center items-center"
    >
      <Pagination />
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
import { useRoute, useRouter, RouterView } from "vue-router";
import { useStore } from "vuex";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "View",
  components: { RouterView, Pagination },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const title = computed(() => route.meta.title);
    const showSearch = computed(() => route.meta.showSearch);
    const showFooter = computed(() => route.meta.showFooter);
    const keyword = ref("");

    const goBack = () => router.go(-1);

    const search = () => {
      const model = route.meta.model;
      const searchField = route.meta.searchField;
      store.commit(`${model}/updateParams`, {
        ...store.state[model].params,
        page: 1,
        keywords: { [searchField]: keyword.value },
      });
      store.dispatch(`${model}/pagination`);
    };

    return {
      title,
      showSearch,
      showFooter,
      keyword,
      search,
      goBack,
    };
  },
});
</script>
