import { createStore } from "vuex";
import axios from "axios";
import helper from "./helper.js";
import User from "./user.js";
import BaseStore from "./base";
import { OA_URL } from "@/config.js";

const models = ["company", "config"];
function toStore(models) {
  let tmp = {};
  models.forEach((m) => (tmp[m] = new BaseStore(m).toStore()));
  return tmp;
}

export default createStore({
  state: {
    loading: false,
    systemConfig: {},
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status;
    },
    setSystemConfig(state, payload) {
      state.systemConfig = payload;
    },
  },
  actions: {
    async getSystemConfig({ commit }) {
      const res = await axios.get(`${OA_URL}/config/all/in_dict`);
      commit("setSystemConfig", res.data);
      return res;
    },
  },
  modules: {
    helper,
    user: new User().toStore(),
    ...toStore(models),
  },
});
