import { defineComponent, h } from "vue";
import NavCollapse from "./NavCollapse";
import { div } from "@/utils/tag";

export default defineComponent({
  name: "NavPanel",
  components: { NavCollapse },
  props: {
    title: String,
    navs: { type: Array, required: true },
    panelStyle: {
      type: String,
      default: "flex flex-col flex-shrink-0 w-56 bg-gray-100",
    },
    titleStyle: {
      type: String,
      default:
        "min-w-full h-20 flex justify-center items-center text-gray-600 text-xl font-bold tracking-wider",
    },
  },
  setup(props) {
    return () =>
      div({ class: props.panelStyle }, [
        div({ class: props.titleStyle }, props.title),
        h(NavCollapse, { navs: props.navs }),
      ]);
  },
});
