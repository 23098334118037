import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () => import("../views/About.vue"),
  },
  {
    path: "/user/login",
    name: "Login",
    meta: { showSearch: false, showFooter: false },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/fj_tax/wage",
    name: "TaxWage",
    meta: {
      title: "税务工资表",
      showSearch: false,
      showFooter: false,
    },
    component: () => import("@/views/fj_tax/Wage.vue"),
  },
  {
    path: "/fj_tax/sbqc",
    name: "Sbqc", // 申报清册
    meta: {
      title: "申报清册",
      showSearch: true,
      showFooter: true,
      model: "company",
      searchField: "name",
    },
    component: () => import("@/views/fj_tax/Sbqc.vue"),
  },
  {
    path: "/fj_tax/sbqc_record/:uid",
    name: "SbqcRecord", // 申报清册
    meta: {
      title: "申报清册查看",
      showSearch: false,
      showFooter: false,
      model: "company",
    },
    component: () => import("@/views/fj_tax/SbqcRecord.vue"),
  },
  {
    path: "/company/edit/:uid",
    name: "CompanyEdit",
    meta: {
      title: "编辑公司信息",
      showSearch: false,
      showFooter: false,
      model: "company",
    },
    component: () => import("@/views/company/Edit.vue"),
  },
  {
    path: "/tools/ocr",
    name: "OCR",
    meta: {
      title: "图像识别",
      showSearch: false,
      showFooter: false,
    },
    component: () => import("@/views/tools/Ocr.vue"),
  },
  {
    path: "/system/config",
    name: "SystemConfig",
    meta: {
      title: "系统配置",
      showSearch: false,
      showFooter: false,
      model: "config",
    },
    component: () => import("@/views/system/Config.vue"),
  },
  {
    path: "/system/config/:uid",
    name: "SystemConfigEdit",
    meta: {
      title: "系统配置编辑",
      showSearch: false,
      showFooter: false,
      model: "config",
    },
    component: () => import("@/views/system/ConfigEdit.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.state.user.me.token || localStorage.getItem("token");
  if (to.path === "/user/login" || store.state.user.me.name) {
    next();
  } else {
    if (token) {
      store
        .dispatch("user/getMe", token)
        .then(() => next())
        .catch(() => next({ path: "/user/login" }));
    } else {
      next({ path: "/user/login" });
    }
  }
});

export default router;
