import axios from "axios";
import { HELPER_URL } from "@/config.js";
import { ElNotification } from "element-plus";
import { getIndexByKeyValue } from "@/utils/common.js";

export default {
  namespaced: true,
  state: {
    qrList: [],
  },
  mutations: {
    pushQr(state, item) {
      state.qrList.push(item);
    },
    removeQr(state, item) {
      const index = getIndexByKeyValue(state.qrList, item);
      if (index > -1) state.qrList.splice(index, 1);
    },
  },
  actions: {
    async run(ctx, { pkg, func, kwargs }) {
      return await axios.post(`${HELPER_URL}/run/${pkg}/${func}`, kwargs);
    },
    async task(ctx, { pkg, func, kwargs }) {
      const res = await axios.post(`${HELPER_URL}/task/${pkg}/${func}`, kwargs);
      if (res.data) {
        ElNotification.success({ message: `任务${pkg}.${func}已在后台运行` });
      } else {
        ElNotification.error({
          message: `任务${pkg}.${func}后台运行失败, ${res.data}`,
        });
      }
    },
    async threading(ctx, { pkg, func, kwargsList }) {
      const res = await axios.post(
        `${HELPER_URL}/threading/${pkg}/${func}`,
        kwargsList
      );
      if (res.data) {
        ElNotification.success({
          message: `多线程任务${pkg}.${func}已在后台运行`,
        });
      } else {
        ElNotification.error({
          message: `多线程任务${pkg}.${func}后台运行失败, ${res.data}`,
        });
      }
    },
    async upload(ctx, files) {
      let form = new FormData();
      files.forEach((i) => form.append("files", i));
      const headers = { "Content-Type": "multipart/form-data" };
      return await axios.post(`${HELPER_URL}/upload`, form, { headers });
    },
    /*
    async store({ commit }) {
      // 这里不用axios，因为axios绑定了Loading，这个每隔500ms就会轮询一次，回导致画面跟着刷新
      // const res = await axios.get(`${HELPER_URL}/store`);
      const api = axios.create({
        baseURL: HELPER_URL,
      });
      const res = await api.get("/store");
      console.log(res);
      // 这个的mutation的namespaced只能是helper
      if (res.data) commit(res.data["mutation"], res.data["payload"]);
    },
    */
  },
};
