export function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function toggleElmentById(eId) {
  const element = document.getElementById(eId);
  if (element) {
    if (element.style.display === "none") {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
  }
}

export function getItemByKeyValue(list, key, value, include = false) {
  for (const i of list) {
    if (
      i[key] !== undefined &&
      (i[key] === value || (include && i[key] && i[key].indexOf(value) >= 0))
    ) {
      return i;
    }
  }
  return null;
}

export function getConfigByKey(configList, key) {
  const config = getItemByKeyValue(configList, 'key', key)
  if (config && config.value) return JSON.parse(config.value)
}

export function getIndexByKeyValue(list, key, value) {
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] === value) {
      return i;
    }
  }
  return -1;
}

export function getItemsByKeyValueInclude(list, key, value) {
  return list.filter(
    (i) => i[key] !== undefined && i[key] && i[key].indexOf(value) >= 0
  );
}
