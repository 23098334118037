import axios from "axios";
import BaseStore from "./base";

export default class User extends BaseStore {
  constructor() {
    super("user", true);
  }

  state() {
    return {
      ...super.state(),
      me: {
        name: null,
        uid: null,
        permisson: 0,
        token: localStorage.getItem("token") || "",
      },
    };
  }

  actions() {
    const base_url = this.base_url;
    return {
      ...super.actions(),
      async login({ commit }, { name, password }) {
        const res = await axios.post(`${base_url}/login`, { name, password });
        commit("setMe", res.data);
        return res;
      },
      async getMe({ commit }, token) {
        const res = await axios.get(`${base_url}/me`);
        commit("setMe", { ...res.data, token });
        return res;
      },
      logout({ commit }) {
        commit("clearMe");
      },
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      setMe(state, user) {
        state.me = user;
        localStorage.setItem("token", user.token);
        axios.defaults.headers.common["Token"] = user.token;
      },
      clearMe(state) {
        state.me = { name: "", uid: "", permisson: 0, token: "" };
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Token"];
      },
    };
  }
}
